.calendarContainer {
    width: 90%;
    max-width: 100%;
    font-size: 0.8rem;
    margin: 0 auto;
  }
  
  /* Calendar styles */
  .calendar :global(.fc) {
    --fc-border-color: #e5e7eb;
    --fc-today-bg-color: #D3D3D3;
    --fc-event-border-color: transparent;
    --fc-event-text-color: #fff;
    --fc-page-bg-color: white;
    --fc-small-font-size: 0.9rem;
  }
  
  .calendar :global(.fc-theme-standard td),
  .calendar :global(.fc-theme-standard th) {
    border-color: var(--fc-border-color);
  }
  
  .calendar :global(.fc-theme-standard .fc-scrollgrid) {
    border: none;
  }
  
  .calendar :global(.fc-col-header-cell) {
    padding: 8px 0;
    background-color: #f8fafc;
  }
  
  .calendar :global(.fc-daygrid-day) {
    cursor: pointer;
  }
  
  .calendar :global(.fc-daygrid-day:hover) {
    background-color: #f1f5f9;
  }
  
  .calendar :global(.fc-daygrid-day-number) {
    font-size: 0.9em;
    padding: 6px 8px !important;
  }
  
  .calendar :global(.fc-header-toolbar) {
    margin-bottom: 1em !important;
  }
  
  .calendar :global(.fc-button) {
    background-color: #f1f5f9 !important;
    border-color: #e5e7eb !important;
    color: #475569 !important;
    font-weight: normal !important;
    padding: 0.3rem 0.5rem !important;
    font-size: 0.8rem !important;
    box-shadow: none !important;
    border-radius: 6px !important;
  }
  
  .calendar :global(.fc-button:hover) {
    background-color: #e2e8f0 !important;
  }
  
  .calendar :global(.fc-button-active) {
    background-color: #cbd5e1 !important;
  }
  
  .calendar :global(.fc-event) {
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 0.8em;
    font-weight: 500;
    cursor: pointer;
    margin: 2px 0;
  }
  
  .calendar :global(.fc-h-event) {
    background-color: var(--fc-event-bg-color, #3b82f6);
    border: none;
  }
  
  .calendar :global(.fc-daygrid-event-harness) {
    margin: 2px 0;
  }
  
  .calendar :global(.fc-event-title) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .calendar :global(.fc-toolbar-title) {
    font-size: 1.1rem !important;
    font-weight: 600;
    color: #334155;
    
  }
  
  /* เพิ่ม styles สำหรับ tooltip */
  .calendar :global(.tooltip-title) {
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 4px;
  }
  
  .calendar :global(.tooltip-description) {
    font-size: 0.8rem;
    color: #4b5563;
    margin-bottom: 4px;
  }
  
  .calendar :global(.tooltip-date) {
    font-size: 0.8rem;
    color: #6b7280;
    margin-top: 2px;
  }
  
  .calendar :global(.tippy-box) {
    background-color: white;
    color: #1f2937;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
    padding: 8px 10px;
    max-width: 300px !important;
  }
  
  .calendar :global(.tippy-arrow) {
    color: white;
  }
  
  .calendar :global(.event-title-only) {
    font-size: 0.8rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
  }
  
  /* Responsive styles */
  @media (max-width: 640px) {
    .calendar :global(.fc-toolbar-title) {
      font-size: 1rem !important;
    }
    
    .calendar :global(.fc-header-toolbar) {
      flex-direction: column;
      gap: 0.5rem;
    }
  }