/* filepath: d:\Project\Frontend\my-app\src\styles\markdown.css */
/* ลบการใช้ Tailwind prose และใช้การกำหนดสไตล์เองแทน */

.markdown-body {
  color: #333;
  line-height: 1.6;
  font-size: 1rem;
  text-align: left; /* บังคับให้ข้อความชิดซ้ายเสมอ */
}

/* กำหนด heading styles */
.markdown-body h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #1e40af;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.3em;
  text-align: left;
}

.markdown-body h2 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #2563eb;
  margin-top: 1.8rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 0.2em;
  text-align: left;
}

.markdown-body h3 {
  font-size: 1.25em;
  font-weight: 600;
  color: #3b82f6;
  margin-top: 1em;
  margin-bottom: 0.5em;
  text-align: left;
}

.markdown-body h4 {
  font-size: 1em;
  font-weight: 600;
  margin-top: 1em;
  margin-bottom: 0.5em;
  text-align: left;
}

/* ข้อความพื้นฐาน */
.markdown-body p {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: left;
  white-space: pre-wrap;
  line-height: 1.7;
}

/* ลิสต์ */
.markdown-body ul,
.markdown-body ol {
  padding-left: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: left;
}

.markdown-body ul { list-style-type: disc; }
.markdown-body ol { list-style-type: decimal; }

.markdown-body li {
  margin-bottom: 0.5rem;
}

.markdown-body li > p {
  margin-top: 0;
}

/* ข้อความอ้างอิง */
.markdown-body blockquote {
  padding: 0.5em 1em;
  color: #6b7280;
  border-left: 0.25em solid #ddd;
  margin: 0 0 16px;
  background-color: #f9fafb;
  text-align: left;
}

/* โค้ด */
.markdown-body pre {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 1rem;
  overflow-x: auto;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 0.85em;
  line-height: 1.45;
  margin-bottom: 16px;
  text-align: left;
  white-space: pre;
}

.markdown-body code {
  background-color: #f0f0f0;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
  font-size: 0.85em;
}

/* ตาราง */
.markdown-body table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 16px;
  text-align: left;
}

.markdown-body table th,
.markdown-body table td {
  border: 1px solid #ddd;
  padding: 6px 13px;
  text-align: left;
}

.markdown-body table th {
  background-color: #f6f8fa;
  font-weight: 600;
}

/* รูปภาพ */
.markdown-body img {
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  display: block;
  margin: 1.5em auto;
  border-radius: 8px;
}

/* ขีดเส้นใต้ */
.markdown-body hr {
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0;
}

/* ลิงก์ */
.markdown-body a {
  color: #3182ce;
  text-decoration: underline;
}

.markdown-body a:hover {
  text-decoration: none;
}

/* ตัวหนา ตัวเอียง */
.markdown-body strong {
  font-weight: 600;
}

.markdown-body em {
  font-style: italic;
}

/* เพิ่ม CSS สำหรับ alignment */

.markdown-body div[align="center"] {
  text-align: center !important;
  width: 100%;
  margin: 1em 0;
}

.markdown-body div[align="right"] {
  text-align: right !important;
  width: 100%;
  margin: 1em 0;
}

.markdown-body div[align="left"] {
  text-align: left !important;
  width: 100%;
  margin: 1em 0;
}

/* กำหนดสไตล์สำหรับข้อความที่อยู่ในแต่ละ alignment */
.markdown-body div[align="center"] p,
.markdown-body div[align="center"] h1,
.markdown-body div[align="center"] h2,
.markdown-body div[align="center"] h3,
.markdown-body div[align="center"] h4,
.markdown-body div[align="center"] h5,
.markdown-body div[align="center"] h6 {
  text-align: center !important;
}

.markdown-body div[align="right"] p,
.markdown-body div[align="right"] h1,
.markdown-body div[align="right"] h2,
.markdown-body div[align="right"] h3,
.markdown-body div[align="right"] h4,
.markdown-body div[align="right"] h5,
.markdown-body div[align="right"] h6 {
  text-align: right !important;
}

/* ให้ขึ้นบรรทัดใหม่มีระยะห่างที่เหมาะสม */
.markdown-body br,
.markdown-body .md-line-break {
  display: block;
  content: "";
  margin-top: 0.5em;
}

/* ปรับแต่งลำดับความสำคัญของ CSS */
.markdown-body h1.heading-h1,
.markdown-body h2.heading-h2,
.markdown-body h3.heading-h3,
.markdown-body p.paragraph {
  text-align: inherit !important;
}

/* ปรับแต่งระยะห่างของย่อหน้า */
.markdown-body p + p {
  margin-top: 1em;
}

.markdown-body div[align="center"] p,
.markdown-body div[align="center"] h1,
.markdown-body div[align="center"] h2,
.markdown-body div[align="center"] h3 {
  text-align: center !important;
  width: 100%;
}

/* เพิ่มสไตล์เฉพาะสำหรับหน้าแสดงบทความ */

/* ปรับปรุงลำดับความสำคัญของ CSS ในการจัดวางตำแหน่ง */
.article-content .markdown-body h1,
.article-content .markdown-body h2,
.article-content .markdown-body h3,
.article-content .markdown-body h4,
.article-content .markdown-body h5,
.article-content .markdown-body h6 {
  margin-top: 1.5em;
  margin-bottom: 0.75em;
  font-weight: 600;
  line-height: 1.25;
}

.article-content .markdown-body h1 {
  font-size: 2em;
  padding-bottom: 0.3em;
  border-bottom: 1px solid #eaecef;
}

.article-content .markdown-body h2 {
  font-size: 1.5em;
  padding-bottom: 0.3em;
  border-bottom: 1px solid #eaecef;
}

.article-content .markdown-body h3 {
  font-size: 1.25em;
}

.article-content .markdown-body img {
  max-width: 100%;
  margin: 1em 0;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.article-content .markdown-body a {
  color: #3182ce;
  text-decoration: none;
}

.article-content .markdown-body a:hover {
  text-decoration: underline;
}

/* ปรับแต่งการแสดงผลให้สวยงามบน mobile */
@media (max-width: 640px) {
  .article-content .markdown-body h1 {
    font-size: 1.75em;
  }
  
  .article-content .markdown-body h2 {
    font-size: 1.4em;
  }
  
  .article-content .markdown-body h3 {
    font-size: 1.2em;
  }
}

/* เพิ่ม CSS สำหรับการพิมพ์ */
@media print {
  body * {
    visibility: hidden;
  }
  
  .markdown-body,
  .markdown-body * {
    visibility: visible;
  }
  
  .markdown-body {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 2cm !important;
  }
  
  /* ซ่อนส่วนที่ไม่จำเป็นขณะพิมพ์ */
  .article-header-image,
  .article-author-box,
  .related-articles,
  .article-nav-buttons {
    display: none !important;
  }
}