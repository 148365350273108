.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@keyframes slidein {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.animation-slidein300 {
  animation: slidein 1s ease 300ms;
}

.animation-slidein500 {
  animation: slidein 1s ease 500ms;
}

.animation-slidein700 {
  animation: slidein 1s ease 700ms;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* สร้างคลาสสำหรับการใช้งาน */
.fade-in {
  animation: fadein 1.5s ease-out;
}

/* ในไฟล์ CSS ของคุณ */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

.scrollbar-thin {
  scrollbar-width: thin;  /* Firefox */
}

.scrollbar-thin::-webkit-scrollbar {
  width: 6px;  /* Chrome, Safari, Opera */
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.5);
  border-radius: 20px;
}

.scrollbar-thin:hover::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.8);
}

/* เพิ่มคลาส scrollbar-hover-show */
.scrollbar-hover-show {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrollbar-hover-show::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

/* เมื่อ hover จะแสดง scrollbar */
.scrollbar-hover-show:hover {
  scrollbar-width: thin;  /* Firefox */
}

.scrollbar-hover-show:hover::-webkit-scrollbar {
  display: block;
  width: 6px;  /* Chrome, Safari, Opera */
}

.scrollbar-hover-show:hover::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-hover-show:hover::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.5);
  border-radius: 20px;
}

.scrollbar-hover-show:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(203, 213, 225, 0.8);
}